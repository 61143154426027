import {useState,useEffect} from 'react';

export default function useResponsive() {

    const size = {
        mobile_min: '0',
        mobile_med: '420',
        mobile_max: '767',
        tablet_min: '768',
        tablet_max: '991',
        desktop_min: '992',
        desktop_max: '1440',
    }

    const device = {
        mobileSmall: `(min-width: ${size.mobile_min}px) and (max-width: ${size.mobile_med}px)`,
        mobileMed: `(min-width: ${size.mobile_med}px) and (max-width: ${size.mobile_max}px)`,
        mobileAll: `(max-width: ${size.mobile_max}px)`,
        tablet: `(min-width: ${size.tablet_min}px) and (max-width: ${size.tablet_max}px)`,
        tabletAll: `(max-width: ${size.tablet_max}px)`,
        desktop: `(min-width: ${size.desktop_min}px) and (max-width: ${size.desktop_max}px)`,
        desktopAll: `(max-width: ${size.desktop_max}px)`,
    }

    function ScreenWidth () {

        let initialWidth;
        if (typeof window !== `undefined`) {
            initialWidth = parseInt(window.innerWidth);
        }
        
        const [width, setWidth] = useState(initialWidth);

        useEffect(() => {

            function updateWidth() {
                setWidth(window.innerWidth);
            }

            window.addEventListener('resize', updateWidth);
            updateWidth();

            return () => window.removeEventListener('resize', updateWidth);
        }, []);

        return width;
        }

    return {size, device, ScreenWidth}

}


// import useResponsive from '../../hooks/useResponsive';
// const { size,device,ScreenWidth } = useResponsive();


// const Width = ScreenWidth(); --> Width almacena y actualiza el valor de la resolucion, va dentro de la funcion a renderizar