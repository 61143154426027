import React from 'react';
import styled from '@emotion/styled';
import IconsImg from './IconsImg';


const MediaIcons = styled.div`
    position:fixed;
    right:1.5rem;
    top:10rem;
    display:flex;
    flex-direction:column;
    z-index:999;
    @media (max-width:767px){
        right:.5rem;
    }
    a{
        margin-bottom:1rem;
        filter: brightness(60%);
        &:hover{
            filter: brightness(100%);
        }
    }
`;

export default function MediaFloat() {
    const {FacebookIcon,InstagramIcon,WhatsAppIcon} = IconsImg();
    return (
        <MediaIcons>
            <a href='https://www.facebook.com/profile.php?id=100019604204995' target="_blank" rel="noreferrer"> <img src={FacebookIcon} alt='SGL Facebook' /> </a>
            <a href='https://www.instagram.com/sglseriegold/' target='_blank' rel="noreferrer"> <img src={InstagramIcon} alt='SGL Instagram' /> </a>
            <a href='https://api.whatsapp.com/send?phone=584249066267&text=Hola,%20necesito%20informaci%C3%B3n%20sobre%20sus%20productos.' target="_blank" rel="noreferrer"> <img src={WhatsAppIcon} alt='SGL WhatsApp' /> </a>
        </MediaIcons>
    )
}
