import React from 'react';
import {Link} from 'gatsby';
import styled from '@emotion/styled';

import useResponsive from '../hooks/useResponsive';
const { device } = useResponsive();

const Nav = styled.nav`
    display:none;
    @media (min-width: 768px){
        display:block;
        padding: 0;
        display:flex;
        flex-direction: row;
    }
`;

const NavMobile = styled.nav`
    display:none;
    @media ${device.mobileAll}{
        display:block;
        position:absolute;
        top:0;
        right:0rem;
        left:0;
    }
    input[type=checkbox] {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    ul{
        display:none;
        background-color:#333;
        text-align:center;
    }
    label{
        margin:0;
        position:absolute;
        right:1rem;
        top:1rem;
        z-index:999;
        display:flex;
        flex-direction:column;
        justify-content:space-around;
        align-items:center;
        height:3rem;
        width:3rem;
        @media (min-width: 768px){
            display:none;
        }
        &:hover{
            cursor: pointer;
        }
        span{
            height:3px;
            width:3rem;
            background-color:#ffff;
            border-radius:3px;
        }
    }
    
    input[type=checkbox]:checked ~ ul {
        display:block;
        position:fixed;
        left:0;
        right:0;
        top:0;
        bottom:0;
        padding:5rem 5rem 5rem 5rem;
        z-index:999;
        background-color:var(--bgcolor);
        span{
            font-size:2rem;
        }
        label{
            span{
                background-color:var(--s1color);
                transform: rotate(45deg);
                position:absolute;
            }
            span:last-child{
                transform: rotate(-45deg);
            }
        }
    }
`;

const NavLink = styled(Link)`
    color: var(--txcolor);
    font-family: QuubNormal, sans-serif;
    text-decoration: none;
    letter-spacing:2px;
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;

    &:hover{
        color:var(--txcolor70);
    }
    &::before{
        content:'';
        height:3px;
        position:absolute;
        bottom:0;
        width:0%;
        transition:all .5s;
    }
    &:hover::before{
        content:'';
        height:3px;
        position:absolute;
        bottom:0;
        width:100%;
        background-color:var(--s1color);
    }
    @media (min-width:768px){
        margin-right: 1rem;
        &:last-of-type { margin-right: 0; }
    }

    span {
        padding: .5rem;
        border-bottom: 2px solid transparent;

        @media ${device.mobileAll} {
            width:100%;
        }
    }

    &.pagina-actual span{
        border-bottom: 2px solid #FFF;
    }
`;

export default function NavMenu() {
    return (
    <>
        
        <Nav>
            <NavLink
                to={'/'}
                activeClassName="pagina-actual"
            > <span>Inicio</span> </NavLink>
            <NavLink 
                to={'/productos-sgl/'}
                activeClassName="pagina-actual"
            > <span>Productos</span> </NavLink>
            <NavLink 
                to={'/resellers/'}
                activeClassName="pagina-actual"
            > <span>Contacto</span> </NavLink>
        </Nav>
    
        <NavMobile>
            <input type="checkbox" id="mobilebtn"></input>
            <label className='LabelMenu' htmlFor="mobilebtn">
                <span></span>
                <span></span>
                <span></span>
            </label>
            <ul>
                <NavLink
                    to={'/'}
                    activeClassName="pagina-actual"
                > <span>Inicio</span> </NavLink>
                <NavLink 
                    to={'/productos-sgl/'}
                    activeClassName="pagina-actual"
                > <span>Productos</span> </NavLink>
                <NavLink 
                    to={'/resellers/'}
                    activeClassName="pagina-actual"
                > <span>Contacto</span> </NavLink>

                <input type="checkbox" id="mobilebtn"></input>
                <label className='LabelMenu-active' htmlFor="mobilebtn">
                    <span></span>
                    <span></span>
                </label>
            </ul>
        </NavMobile>
    </>
    );
}
