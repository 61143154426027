import React from 'react';
import Helmet from 'react-helmet';
import { Global, css } from '@emotion/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Header';
import MediaFloat from './MediaFloat';
import Footer from './Footer';
import FondoSilueta from '../images/FondoSilueta.png';

import useFonts from '../fonts/useFonts.js';
const {QuubRegular,QuubMedium,QuubBold,RobotoRegular,LatoRegular} = useFonts();

export default function Layout({ children }) {
  return (
    <>
      <Global 
          styles={css`
              :root{
                --hrcolor: hsl(210, 30%, 8%);
                --bgcolor: hsl(0, 0%, 4%); /* AZUL OSCURO */
                --ftcolor: hsl(210, 70%, 5%); /* AZUL MAS OSCURO */
                --s1color: hsl(333, 100%, 52%); /* FUCSIA */
                --s1hcolor: hsl(333, 90%, 45%); /* FUCSIA */
                --s2color: hsl(230, 100%, 67%); /* MORADO */
                --s3color: hsl(53, 100%, 50%);  /* AMARILLO */
                --txcolor: hsl(0, 0%, 85%);
                --txcolor70: hsl(0, 0%, 70%);
                --thcolor: hsl(333, 100%, 52%); /* FUCSIA */
                --brcolor: #eaeaea;
                --lkcolor: hsl(230, 100%, 67%); /* MORADO */
                --lhcolor: #eaeaea; 
              }
              @font-face {
                  font-family: QuubNormal;
                  src: url(${QuubRegular});
              }
              @font-face {
                  font-family: QuubMedium;
                  src: url(${QuubMedium});
              }
              @font-face {
                  font-family: QuubBold;
                  src: url(${QuubBold});
              }
              @font-face {
                  font-family: Roboto;
                  src: url(${RobotoRegular});
              }
              @font-face {
                  font-family: Lato;
                  src: url(${LatoRegular});
              }
              html{
                  font-size: 62.5% !important;
                  box-sizing:border-box;
              }
              *,*:before,*:after {
                  box-sizing:inherit;
              }
              main{
                  min-height:calc(100vh - 12rem);
                  &:after {
                    content: '';
                    position: fixed;
                    left: 0;
                    top: 0;
                    bottom:0;
                    width: 100%;
                    z-index: -99;
                    background-image: url(${FondoSilueta}),
                        radial-gradient(#444 15%, transparent 16%),
                        radial-gradient(#444 15%, transparent 16%),
                        radial-gradient(#ffffff60 15%, transparent 20%),
                        radial-gradient(#ffffff60 15%, transparent 20%);
                    background-repeat:no-repeat, repeat,repeat,repeat,repeat;
                    background-position: 50% 3rem, 0px 0px, 8px 8px, 0px 1px, 8px 9px;
                    background-size: contain,16px 16px, 16px 16px, 16px 16px, 16px 16px;
                    opacity: .05;
                    }
              }
                input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
                }
                input[type=number] {
                -moz-appearance: textfield;
                }
              body{
                  font-size:16px !important;
                  font-size:1.6rem !important;
                  line-height:1.5 !important;
                  background-color:var(--bgcolor);
                  font-family: Roboto, sans-serif;
              h1, h2, h3, h4{
                  margin:0 !important;
                  line-height:1.5;
                  color:var(--thcolor);
              }
              h1,h2{
                  font-weight:400;
              }
              p{
                  margin:0;
                  color:var(--txcolor);
                  text-align:justify;
                  font-family: Lato, sans-serif;
              }
              ul{
                  list-style:none;
                  margin:0;
                  padding:0;
              }
              .contenedor {
                  max-width:120rem;
                  margin:0 auto;
                  width:95%;
              }
              img {
                  max-width:100%;
              }
              a{
                  text-decoration:none;
                  color:var(--lkcolor);
                  transition:all ease-out .3s;
              }
              a:hover{
                  color:var(--lhcolor);
                  text-decoration:none;
              }
            }
            
            `}
      />

      <Helmet>
          <title>Strong Lab - Serie Gold</title> 
          <meta name="description" content="Los Mejores productos para mejorar y resaltar tu fisico los tiene Strong Lab - Serie Gold" />
          <meta charset="UTF-8"/>
          <meta http-equiv="content-language" content="es"/>
          <meta name="keywords" content="sgl, entrenamiento, anabolicos, testosterona"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0, minimum-scale=1.0"/>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css" integrity="sha256-l85OmPOjvil/SOvVt3HnSSjzF1TUMyT9eV0c2BzEGzU=" crossorigin="anonymous" />
      
      </Helmet>
            
    <Header />

    <main>

    {typeof window !== `undefined` && window.location.pathname !== '/' && <MediaFloat />}
    
    {children}

    </main>
    <Footer />
    </>
  );
}

