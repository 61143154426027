import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link} from 'gatsby';
import NavMenu from './NavMenu';

const HeaderCSS = styled.header`
    z-index:555;
    position:relative;
    min-height:6rem;
    @media (min-width:768px){
        height:6rem;
    }
`;
const HeaderCSS_ = styled.div`
    max-width: 120rem;
    height:6rem;
    position:relative;
    padding:0 5%;
    display:flex;
    align-items:center;
    .logobox{
        color:var(--s1color);
        font-family: QuubBold, sans-serif;
        letter-spacing:1px;
        font-size:1.8rem;
        padding:1rem 3rem;
        border-radius:3px;
        margin-right:3rem;
    }
    img{
        max-width:6rem;
    }

    @media (min-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height:100%;
        margin:auto;
    }
`;

export default function Header() {
    const tyofCheck= typeof window !== `undefined`;
    return (
        <HeaderCSS css={tyofCheck && window.location.pathname !== '/' && css`background-color:#0f0f0f95` } >
            
            <HeaderCSS_>

                <Link className='logobox' to="/"
                    css={tyofCheck && window.location.pathname === '/' ? css`background-color:#0f0f0f90` : css`background-color:#151515`}
                >
                    TEAM STRONG LAB - SERIE GOLD
                </Link>

                <NavMenu />

            </HeaderCSS_>
        </HeaderCSS>
    );
}
