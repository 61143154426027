import React from 'react';
import styled from '@emotion/styled';

const FooterCSS = styled.footer`
    display:flex;
    justify-content:center;
    align-items:center;
    background-color:#0f0f0f95;
    position:relative;
    bottom:0;
`;

const FooterCSS_ = styled.div`
    display:flex;
    flex:1;
    padding:1rem;
    height:6rem;
    p, a {
        margin:auto;
        text-align:center;
        font-size:1.3rem;
        color:#ccc;
    }
`;

export default function Footer() {
    return (
        <FooterCSS>
            <FooterCSS_>

                <p>Strong Lab Serie Gold © {new Date().getFullYear()} </p>

            </FooterCSS_>
        </FooterCSS>
    )
}
