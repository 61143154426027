import BrIcon from '../images/flags/Brasil.png';
import VeIcon from '../images/flags/Venezuela.png';
import CoIcon from '../images/flags/Colombia.png';
import EcIcon from '../images/flags/Ecuador.png';
import FacebookIcon from '../images/social/Facebook.png';
import InstagramIcon from '../images/social/Instagram.png';
import WhatsAppIcon from '../images/social/WhatsApp.png';

export default function IconsImg() {
    
return {BrIcon,VeIcon,CoIcon,EcIcon,FacebookIcon,InstagramIcon,WhatsAppIcon}

}
